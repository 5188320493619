<template>
<div>
    <Carousel autoplay :autoplay-speed="autoTime" v-model="value2" @height="height" loop>
            <CarouselItem v-for="(item,index) in bannerList" v-bind:key="index">
            <div class="demo-carousel"><img v-bind:title="item.title"  v-bind:src="item.img_path" width="100%" /></div>
            </CarouselItem>
    </Carousel>
</div>
</template>
<style>
  .demo-carousel{overflow:hidden;height:500px;}
  .ivu-carousel-dots li button {width:50px;height:15px;}
</style>

<script>
export default {
        name:'CarouselMap',
        data () {
            return {
                value2: 0,
                autoTime:4000,
                height:'500px',
                bannerList:''

            }
        },

mounted() {
  this.$axios.post(window.configs.svcProt + "://" + window.configs.svcIpPort + '/get_banner_list?token='+this.$store.state.token).then(res=>{
    this.bannerList=res.data.result;
  });
}
    
}
</script>
