<template>
    <div id="i-welfare-box">
        <!--标题开始-->
        <div id="i-about-title">
            <font>————————</font> 公益活动 <font>————————</font>
            <p>关注公益事业，为社会做一份贡献，为人间送一份温暖！</p>
        </div>

        <!--内容开始-->
        <div class="i-welfare-content left">
            <!--左边开始-->
            <div class="i-welfare-content-left left">
                <div class="i-welfare-left-image left"> <img :src="firstData.img_path" height="300" /> </div>
                <!--标题开始-->
                <h1>{{firstData.title}}</h1>
                <p>{{firstData.content}}</p>
                <!--更多开始-->
                <a :href="'/#/social_responsibility_detail?social='+firstData.id" :title="firstData.title" >
                <p id="i-welfare-more"><button style="cursor:pointer;">&nbsp;&nbsp;&nbsp;&nbsp;查看更多 + </button></p>
                </a>
            </div>
            
            <!--右边开始-->
            <div class="i-welfare-right right">
                <!--内容开始-->
                <a :href="'/#/social_responsibility_detail?social='+item.id" :title="item.title" v-for="item in data" :key="item.id">
                <div class="i-welfare-right-li left">
                    <!--日期开始-->
                    <div class="i-welfare-date left">
                        <p>{{item.day}}</p>
                        <p>{{item.date}}</p>
                    </div>

                    <!--内容和简介开始-->
                    <div class="i-welfare-right-content left">
                        <h1>{{item.title}}</h1>
                        <p>{{item.content}}</p>
                    </div>
                </div>
                </a>

            </div>
            <!--右边结束-->
        </div>
        <!--内容结束-->
</div>
</template>

<script>
export default {
   name:"",
   data() {
      return {
          data:'',
          firstData:''
      }
   },

mounted() {
  this.$axios.post(window.configs.svcProt + "://" + window.configs.svcIpPort + '/get_index_welfare.html?token='+this.$store.state.token).then(res=>{
    this.data=res.data.data;
    this.firstData=res.data.firstData;
  });
},
}
</script>

<style scoped>
    .i-welfare-right-content p{line-height:25px;color:#999;}
    .i-welfare-right-content h1{line-height:35px;font-size:16px;color:#666;font-weight:bold;cursor:pointer;}
    .i-welfare-right-content h1:hover{color:#999;}
    .i-welfare-right-content{width:630px;height:100px;margin-left:20px;margin-top:10px;}
    .i-welfare-date p:first-child{font-size:35px;margin-top:20px;font-weight:bold;}
    .i-welfare-date p:last-child{background:#f29700;margin-top:20px;font-size:15px;}
    .i-welfare-date p{line-height:30px;color:#fff;text-align:center;}
    .i-welfare-date{width:100px;height:100px;margin:10px;background:#f5be64;}
    .i-welfare-right-li{width:790px;height:120px;background:#f8f8f8;border:1px solid #eee;margin-bottom:20px;}
    .i-welfare-right{width:790px;height:380px;margin-top:50px;margin-right:20px;}
    #i-welfare-more{line-height:50px;margin-top:20px;}
    #i-welfare-more button{width:100px;line-height:30px;background:rgb(242,151,0);color:#fff;border:none;border-radius:5px;}
    .i-welfare-content-left h1{padding-left:10px;line-height:40px;font-size:16px;cursor:pointer;}
    .i-welfare-content-left h1:hover{color:#999;}
    .i-welfare-content-left p{color:#999;font-size:15px;padding-left:10px;width:100%;}
    .i-welfare-left-image{width:550px;height:300px;overflow:hidden; cursor:pointer;}
    .i-welfare-left-image img:hover{transform: scale(1.4);}
    .i-welfare-left-image img{min-width:550px;min-height:300px;transition:all 2s;}
    .i-welfare-content-left{width:550px;height:450px;margin-top:50px;margin-left:10px;}
    #i-welfare-box{width:1440px;height:700px;margin:auto;}
    .i-welfare-content{width:100%;}
</style>
