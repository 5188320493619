<template>
<div>
   <!--顶部导航菜单-->
    <div id="header-box" class="width-bai"> <Header></Header> </div>

    <!--轮播图-->
    <div id="carousel-map" class="width-bai"> <carousel-map></carousel-map> </div>

    <!--关于我们-->
    <div id="index-about-box" class="width-bai"> <I-about></I-about> </div>

    <!--业务领域-->
    <div id="index-business" class="width-bai"> <Business></Business> </div>
    
    <!--新闻中心-->
    <div> <News></News> </div>

    <!--业务类别-->
    <!-- <div id="index-business-type" class="width-bai">
      <business-type></business-type>
    </div> -->
    
    <!--公益活动-->
    <div id="index-public-welfare" class="width-bai"> <public-welfare></public-welfare> </div>
   
   <!--地图-->
   <!-- <div id="index-business-map" class="width-bai">
     <business-map></business-map>
   </div> -->
   
   <!--浮动联系方式-->
   <contact-call></contact-call>

   <!--底部-->
   <div class="index-footer left width-bai"> <Footer></Footer> </div>

</div>
</template>

<script>
import CarouselMap from './CarouselMap.vue'
import IAbout from './IAbout.vue'
import Business from './Business.vue'
import News from './News.vue'
// import BusinessType from './components/BusinessType.vue'
import PublicWelfare from './PublicWelfare.vue'
// import BusinessMap from './components/BusinessMap.vue'
import ContactCall from './ContactCall.vue'

import Header from '../layout/Header.vue'
import Footer from '../layout/Footer.vue'

export default {
name:"",
data() {
return {
}
},

components: {
   Header,
   CarouselMap,
   IAbout,
   Business,
   News,
   PublicWelfare,
   ContactCall,
   Footer
}

}
</script>

<style scoped>
  #index-about-box{min-height:800px;float:left;}
  #header-box{height:160px; float:left;}
  #carousel-map{margin:auto;height:500px;overflow: hidden;float:left;}
  #index-business{min-height:1000px;float:left;}
  #index-business-type{height:600px;float:left;}
  #index-public-welfare{height:700px;float:left;}
  #index-business-map{height:800px;float:left;}
  .index-footer{height:550px;}
</style>
