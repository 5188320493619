<template>
 <div>
  <div id="i-about-box">
    
    <!--标题-->
    <div id="i-about-title">
        <font>————————</font> 关于我们 <font>————————</font>
        <p>以“保安全、重信誉、讲质量、抓进度、促环保”为宗旨!</p>
    </div>

    <!--图片列表-->
    <div id="i-about-image">
        <div class="i-about-img-box left">
          <div class="i-about-content left" v-for="item in imgItem" v-bind:key="item.id">
            <div class="i-about-img left"> <img v-bind:src="item.img_path" width="400" height="300" /> </div>
            <p>{{item.title}}</p>
          </div>
        </div>

        <!--简介内容-->
        <div id="i-image-text"> {{content}} </div>

        <!--按钮-->
        <div id="i-image-button" class="left">
          <a href="/#/about_us" title="查看更多"> <button>查看更多 &nbsp;<Icon type="ios-arrow-dropright" size="17"/></button> </a>
        </div>
    </div>
  </div>

  <!--四个图标开始-->
    <div id="i-image-icon">
      <div id="i-image-icon-content">


        <div class="i-image-icons left">
          <div> <img src="~images/zhiliangbaozheng.png" width="150" /> </div>
          <p>质量保证</p>
        </div>

        <div class="i-image-icons left">
          <div> <img src="~images/kehuzhishang.png" width="150" /> </div>
          <p>客户至上</p>
        </div>

        <div class="i-image-icons left">
          <div> <img src="~images/xinshouchengnuo.png" width="150" /> </div>
          <p>信守承诺</p>
        </div>

        <div class="i-image-icons left">
          <div> <img src="~images/shouhoufuwu.png" width="150" /> </div>
          <p>售后保障</p>
        </div>

      </div>
    </div>
    </div>
</template>

<script>

export default {
  name: '',

  data() {
    return {
      imgItem:''
    };
  },

mounted() {
  this.$axios.post(window.configs.svcProt + "://" + window.configs.svcIpPort + '/get_project.html?token='+this.$store.state.token)
  .then(res=>{
    this.imgItem=res.data.result.images;
    this.content=res.data.result.content;
  });
},
};
</script>

<style scoped>
    .i-image-icons p,.i-image-icons div{font-size:25px;color:#fff;line-height:50px;text-align:center;}
    .i-image-icons{width:300px;height:220px;margin-top:95px;margin-left:41px;margin-right:10px;}
    #i-image-icon-content{width:1440px;height:390px;margin:auto;}
    #i-image-icon{width:100%;height:390px;background:url('~images/banner01.jpg');}
    #i-image-button{width:100%;height:60px;margin-top:30px;text-align: center;}
    #i-image-button button{background:rgb(242,151,0);cursor:pointer;color:#fff;border:none;width:150px;line-height:45px;border-radius: 5px;margin-top:20px;}
    #i-image-text{font-size:15px;line-height:30px;margin-top:10px;float:left;}
    .i-about-content p{text-align: center;font-size:16px;line-height: 55px;font-weight:bold;float:left;width:100%;}
    .i-about-content{width:450px;height:400px;margin:15px;}
    .i-about-img-box{width:1440px;height:400px;margin:auto;cursor:pointer;}
    .i-about-img{width:400px;height:300px;text-align:center;overflow:hidden;margin-left:20px;}
    .i-about-img img{transition: all 2s;}
    .i-about-img img:hover{transform: scale(1.4);}
    #i-about-image{width:1440px;height:350px;margin-top:20px;float:left;margin-left:25px;margin-right:25px;}
    #i-about-box{width:1440px;height:800px;margin:auto;}
</style>
