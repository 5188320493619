<template>
<div class="i-news-box left">
        <!--标题开始-->
        <div class="i-news-title left"> 
            <p class="i-news-title-text">新闻动态</p>
            <p class="i-news-ftitle-text">中国电工充分发展“主业突出，多业并举”的优势，不断延伸和扩展业务领域</p>
        </div>

        <!--主题开始-->
        <div class="i-news-content left">
            <div class="i-news-contents">
            
            <!--菱形部分开始-->
            <a v-bind:href="'/#/news_detail?art_id='+firstData.id" v-bind:title="firstData.title">
            <div class="i-news-diamond left">
                <div class="i-news-diamond-image"> <img v-bind:src="firstData.img_path" />  </div>
                <p>{{firstData.title}}</p>
            </div>
            </a>

            <!--右边三项开始-->
            <div class="i-news-list right">
                <a v-for="item in data" v-bind:key="item.id" v-bind:href="'/#/news_detail?art_id='+item.id" v-bind:title="item.title">
                <div class="i-news-list-content left" >
                    <div class="i-news-list-img left"> <img v-bind:src="item.img_path" height="120" /> </div>
                    <div class="i-news-list-title left"> 
                        <h1>{{item.title}}</h1>
                        <p>{{item.content}}</p>
                    </div>
                </div>
                </a>
            </div>

            </div>
        </div>
</div>
</template>
<script>
export default {
   data() {
      return {
          data:'',
          firstData:''
      }
   },

mounted() {
  this.$axios.post(window.configs.svcProt + "://" + window.configs.svcIpPort + '/get_index_news.html?token='+this.$store.state.token).then(res=>{
    this.data=res.data.result.data;
    this.firstData=res.data.result.firstData;
  });
},
}
</script>

<style scoped>
    .i-news-list-title h1{font-size:16px;font-weight:bold;line-height:35px;}
    .i-news-list-title h1:hover{color:#888;}
    .i-news-list-title p{color:#999;line-height:25px;overflow:hidden}
    .i-news-list-title{width:500px;height:130px;margin-left:10px;}
    .i-news-list-img{width:120px;height:120px;margin:5px;overflow:hidden;border-radius:5px;}
    .i-news-list-img img{transition: all 2s;}
    .i-news-list-img img:hover{transform: scale(1.4);}
    .i-news-list-content{width:90%;height:130px;margin-left:5%;margin-top:20px;cursor:pointer;}
    .i-news-contents{width:1440px;height:810px;margin:auto;}
    .i-news-list{width:750px;height:470px;background:#fff;border-radius:5px;margin-right:20px;margin-top:90px;}
    .i-news-diamond p{line-height:30px;font-size:16px;color:#666;padding-left:10px;padding-right:10px;}
    .i-news-diamond-image{border:5px solid #fff;width:440px;height:490px;overflow:hidden;margin-left:5px;}
    .i-news-diamond-image img{transition: all 2s;cursor:pointer;width:440px;height:490px;}
    .i-news-diamond-image img:hover{transform: scale(1.4);}
    .i-news-diamond{width: 450px;height: 530px;transform: rotateZ(0deg)skew(-20deg,0deg);background: #fff;margin-left:80px;margin-top:50px;}
    .i-news-content{width:100%;height:700px;margin-top:40px;}
    .i-news-ftitle-text{line-height:30px;text-align:center;font-size:15px;color:#fff;}
    .i-news-title-text{line-height:40px;text-align:center;font-size:28px;color:#fff;}
    .i-news-box{width:100%;height:810px;background:url('~images/banner02.jpg');}
    .i-news-title{width:100%;min-height:60px;margin-top:30px;}
</style>
